<div class="main-container">
  <div class="box">
    <div class="detail" *ngIf="dataStatus == 'done'; else loader">
      <div class="header">
        <h1 class="title">User Detail</h1>
        <!-- <div class="actions" *ngIf="!editMode">
          <button class="cg primary" (click)="enableForm()">Edit</button>
        </div> -->
      </div>
     
      <div>
        
          <div class="row">
            <div class="col-md-8 col-12">
              <div class="row">
                <div class="col-md-6">
                  <div class="cg-input-group">
                    <label>Name<span class="mendatory"></span></label>
                    {{this.detail.firstName}} {{this.detail.lastName}} 
                  </div>
                 
                </div>
                <div class="col-md-6">
                  <div class="cg-input-group">
                    <label>Email<span class="mendatory"></span></label>
                    {{this.detail.email}}
                  </div>
                 
                </div>
                <div class="col-md-6">
                  <div class="cg-input-group">
                    <label>Gym Name<span class="mendatory"></span></label>
                   {{this.detail?.customer?.schoolName}}   
                  </div>
                 
                </div>

                <div class="col-md-6">
                  <div class="cg-input-group">
                    <label>Phone Number<span class="mendatory"></span></label>
                   {{this.detail?.customer?.phoneNumber}}   
                  </div>
                 
                </div>

                <div class="col-md-6">
                  <div class="cg-input-group">
                    <label>Address<span class="mendatory"></span></label>
                   {{this.detail?.customer?.address}}   
                  </div>
                 
                </div>

                <div class="col-md-6">
                  <div class="cg-input-group">
                    <label>Owner Email<span class="mendatory"></span></label>
                   {{this.detail?.customer?.ownerEmail}}   
                  </div>
                 
                </div>
               
             
               
                
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="photo_upload">
                <label>
                  
                  <div class="upload_photo">
                    <label class="custom-file-upload">
                      <img  [src]="api.userImageUrl(id)" alt="Image Not Found"
                        border-radius="10%" />
                    </label>
                    
                  </div>
                </label>
              </div>
            </div>
          </div>
        
          <!-- <button
            type="submit"
            class="cg primary mx-1"
            [disabled]="waiting.save || !formUpdated"
            [ngClass]="waiting.save ? 'in-progress' : ''"
          >
            Save <span></span><span></span><span></span>
          </button> -->
        
      </div>
    </div>
    <ng-template #loader>
      <div class="loader">
        <img src="/assets/images/rolling-main.svg" alt="spinner" />
      </div>
    </ng-template>
  </div>
</div>

