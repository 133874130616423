import {
  Component,
  ElementRef,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ConstantsService } from 'src/app/services/constants.service';

import { IAlertService } from 'src/app/libs/ialert/ialerts.service';
import { UIHelpers } from 'src/app/helpers/ui-helpers';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { BreadCrum } from 'src/app/interfaces/breadCrum';

import { DomSanitizer } from '@angular/platform-browser';
import { ApiService } from 'src/app/services/api.service';
import { Country, State, City } from 'country-state-city';

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit {
  id: any = -1;
  name: string;
  dataForm: FormGroup;
  formUpdated: boolean = false;
  dataStatus = 'fetching';
  detail: any;
  editMode: boolean = false;
  croppedThumbnail: any;
  thumbnailImage: any = '';
  thmbnailChangedEvent: any;
  selectedFile: any;
  thumbnailModalRef: BsModalRef;
  thumbnail: any = '/assets/img/no_image.png';
  spinnerSVG = '/assets/images/rolling-main.svg';
  countries = Country.getAllCountries();
  states = null;
  cities = null;
  selectedCountry: any;
  selectedState: any;
  selectedCity: any;

  waiting: {
    save: boolean;
  };
  breadCrum: Array<BreadCrum> = [
    {
      link: '/admin/users',
      value: 'Users',
    },
  ];
  filters = {
    status: 'active',
  };
  saveLoading = false;
  constructor(
    
    public api: ApiService,
    private alert: IAlertService,
    private fb: FormBuilder,
    public route: ActivatedRoute,
    private router: Router,
    private ms: BsModalService,
    public ui: UIHelpers,
    private ac: ActivatedRoute,
    public cs: ConstantsService,
    private sanitizer: DomSanitizer
  ) {
    
    this.breadCrum.push({
      link: '/admin/users/detail',
      value: this.name,
      params: {
        id: this.id,
        name: this.name,
      },
    });

    this.waiting = {
      save: false,
    };
    this.dataForm = this.fb.group({
      id: new FormControl(null),
      firstName: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      country: new FormControl(null, [Validators.required]),
      state: new FormControl(null, [Validators.required]),
      city: new FormControl(null, [Validators.required]),
      description: new FormControl(''),
    });

    this.id = this.api.userDetaiId
    console.log('api---call user detail', this.id)
    this.getDetail()
  }
  ngOnInit() {}
  get g() {
    return this.dataForm.controls;
  }
  getDetail() {
    this.api.getUserDetail({ id: this.id }).subscribe((resp: any) => {
      this.dataStatus = 'done';
      if (resp.success) {
        if (resp.data != null) {
          this.detail = resp.data;
          
          console.log('resp----', resp.data)
          
        }
      }
    });
  }

  enableForm() {
    this.editMode = !this.editMode;
    this.dataForm.enable();
    if (!this.g.country.value) {
      this.g.state.disable();
    }
    if (!this.g.state.value) {
      this.g.city.disable();
    }
    this.g.email.disable();
  }
  onCountryChange(event: any, userSelected?: boolean): void {
    const selectedCountryName = event.target.value;
    this.selectedCountry = this.countries.find(
      (country) => country.name === selectedCountryName
    );

    if (this.selectedCountry) {
      const countryCode = this.selectedCountry.isoCode;
      this.states = State.getStatesOfCountry(countryCode);
      if (this.dataForm.enabled) {
        this.g.state.enable();
      }
    } else {
      this.states = [];
    }
    if (userSelected) {
      this.g.state.reset();
      this.g.city.reset();
    }
  }

  onFormChange() {
    this.formUpdated = true;
  }

  onStateChange(event: any, userSelected?: boolean): void {
    const selectedStateName = event.target.value;
    this.selectedState = this.states.find(
      (country) => country.name === selectedStateName
    );

    if (this.selectedState) {
      const stateCode = this.selectedState.isoCode;
      this.cities = City.getCitiesOfState(
        this.selectedCountry.isoCode,
        stateCode
      );
      if (this.dataForm.enabled) {
        this.g.city.enable();
      }
    } else {
      this.cities = [];
    }
    if (userSelected) {
      this.g.city.reset();
    }
  }

  
  
}
